<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
		<tr>
		<td align="center"><a href="http://www.cs.columbia.edu/~jwgu">Jinwei Gu</a>,&nbsp;&nbsp;<a href="http://www.cs.columbia.edu/~ravir">Ravi Ramamoorthi</a>,&nbsp;&nbsp;<a href="http://www.cs.columbia.edu/~belhumeur">Peter Belhumeur</a>,&nbsp;&nbsp;<a href="http://www.cs.columbia.edu/~nayar">Shree Nayar</a></td> 
		</tr>

          <tr>
            <td><div align="center"><img src="https://cave.cs.columbia.edu/old/databases/dirty_glass/images/main.jpg"></div></td>
          </tr>
          <tr>
            <td><div align="center"><router-link to="/repository/Contaminants/about">About the Database</router-link><br>
              <br>
                <router-link to="/repository/Contaminants/texture">Database of Contaminants' Patterns</router-link><br>
                <br>
                <router-link to="/repository/Contaminants/scattering">Database of Contaminants' Scattering Parameters</router-link><br>
                <br>
                <router-link to="/repository/Contaminants/source">Source Code (in Matlab)</router-link><br>
                <br>
              <a href="http://www1.cs.columbia.edu/CAVE/projects/dirty_glass/dirty_glass.php">Project  Page</a></div></td>
          </tr>
          <tr>
            <td><hr>
              <a href="http://www1.cs.columbia.edu/CAVE/databases/dirty_glass/dirty_glass.php">Database Home</a><br>
Contact:&nbsp;<a href="mailto:jwgu@cs.columbia.edu">jwgu@cs.columbia.edu</a><br>
Last modified: 06/02/2007</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>